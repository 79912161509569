<template>
    <game-items-add game-item-variant="models" @added="redirectToEditPage">
        <template #title>{{ $t('models.add') }}</template>
    </game-items-add>
</template>

<script>
import GameItemsAdd from '@/views/game-items/game-items-add/GameItemsAdd.vue';

export default {
    components: {
        GameItemsAdd,
    },

    methods: {
        redirectToEditPage(response) {
            const { model_id: modelId = null } = response;

            if (modelId === null) return;

            this.$router.push({
                name: 'models-edit',
                params: { id: modelId },
            });
        },
    },
};
</script>

<style lang="scss" scoped></style>
